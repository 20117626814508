import { useEffect } from "react";
import Head from "next/head";
import {
  defaultTitle,
  defaultDescription,
  defaultURL
} from "../../data/DataMetatags";
import { putTracker } from "../Tracker";

import ogImage from "../../../public/static/images/png/og-image.png";
import appleTouch144 from "../../../public/apple-touch-icon.png";
import appleTouch72 from "../../../public/apple-touch-icon-precomposed.png";
import appleTouch57 from "../../../public/apple-touch-57.png";

type NextHeadProps = {
  tracker?: string;
  title?: string;
  description?: string;
  url?: string;
  breadcrumb?: object;
  imgContent?: string;
};
const NextHead = ({
  tracker,
  title,
  description,
  url,
  breadcrumb,
  imgContent
}: NextHeadProps) => {
  useEffect(() => {
    if (tracker) {
      putTracker(tracker);
    }
  }, [tracker]);

  const contentFactory = (type: "Company" | "Page") => {
    return {
      "@type": "Corporation",
      name: "Home Credit Indonesia",
      url:
        type === "Company"
          ? "https://www.homecredit.co.id"
          : `${defaultURL + (url || "")}`,
      sameAs: [
        "https://www.facebook.com/homecreditid/",
        "https://twitter.com/homecreditid",
        "https://www.instagram.com/homecreditid/",
        "https://www.linkedin.com/company/homecreditindonesia/"
      ],
      image: {
        "@type": "ImageObject",
        url: imgContent || ogImage,
        height: "630",
        width: "1200"
      },
      telephone: "(021) 2953 9655",
      email: "care@homecredit.co.id",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Jl. TB Simatupang No. 53A South Jakarta, DKI Jakarta",
        postalCode: "12520",
        addressCountry: "Indonesia"
      },
      logo: {
        "@type": "ImageObject",
        url: imgContent || appleTouch144,
        height: "144",
        width: "144"
      },
      location: {
        "@type": "Place",
        name: "Home Credit Indonesia",
        hasMap:
          "https://www.google.com/maps/place/PT.+Home+Credit+Indonesia/@-6.2982384,106.8276681,17z/data=!3m1!4b1!4m5!3m4!1s0x2e69f20806540bed:0x1b9795213b72e92b!8m2!3d-6.2982384!4d106.8298568",
        telephone: "(021) 2953 9655",
        image: {
          "@type": "ImageObject",
          url: imgContent || ogImage,
          height: "630",
          width: "1200"
        },
        logo: {
          "@type": "ImageObject",
          url: imgContent || appleTouch144,
          height: "144",
          width: "144"
        },
        url: "https://www.homecredit.co.id",
        sameAs: [
          "https://www.facebook.com/homecreditid/",
          "https://twitter.com/homecreditid",
          "https://www.instagram.com/homecreditid/",
          "https://www.linkedin.com/company/homecreditindonesia/"
        ],
        geo: {
          "@type": "GeoCoordinates",
          latitude: "1.114400",
          longitude: "103.993540"
        },
        address: {
          "@type": "PostalAddress",
          streetAddress: "Jl. TB Simatupang No. 53A South Jakarta, DKI Jakarta",
          postalCode: "12520",
          addressCountry: "Indonesia"
        }
      },
      founder: "Jaroslav Gaisler",
      foundingDate: "2013",
      foundingLocation: "Jakarta, Indonesia"
    };
  };

  const dataSchemaCompany = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    name: "Home Credit Indonesia",
    description:
      "Home Credit mulai berdiri di Indonesia pada tahun 2013 di area Jakarta. Hingga awal tahun 2017, bisnis kami berkembang tidak hanya di Jabodetabek, tetapi juga di Bandung, Makassar, Surabaya, Yogyakarta, Semarang, Malang, Denpasar, Pekanbaru, Medan, Batam, Palembang, Banjarmasin, Pontianak, Manado, dan Balikpapan. Kami berencana untuk mengembangkan layanan ke seluruh kota-kota di Indonesia hingga tahun 2018.",
    url: "https://www.homecredit.co.id",
    image: imgContent || ogImage,
    sameAs: [
      "https://www.facebook.com/homecreditid/",
      "https://twitter.com/homecreditid",
      "https://www.instagram.com/homecreditid/",
      "https://www.linkedin.com/company/homecreditindonesia/"
    ],
    copyrightHolder: contentFactory("Company"),
    author: contentFactory("Company"),
    creator: {
      "@type": "Organization",
      name: "Home Credit Indonesia",
      alternateName: "Home Credit",
      location: {
        "@type": "Place",
        name: "Home Credit Indonesia",
        alternateName: "Home Credit"
      }
    }
  };

  const dataSchemaPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name: `${title || defaultTitle} - Home Credit`,
    description: description || defaultDescription,
    image: {
      "@type": "ImageObject",
      url: imgContent || ogImage,
      height: "630",
      width: "1200"
    },
    url: `${defaultURL + (url || "")}`,
    mainEntityOfPage: `${defaultURL + (url || "")}`,
    inLanguage: "id_ID",
    headline: `${title || defaultTitle} - Home Credit`,
    copyrightYear: "2018",
    author: contentFactory("Page"),
    publisher: contentFactory("Company"),
    copyrightHolder: contentFactory("Page"),
    breadcrumb: {
      "@type": "BreadcrumbList",
      itemListElement: breadcrumb
    }
  };

  return (
    <Head>
      <title>{title || defaultTitle} - Home Credit</title>
      <meta name="description" content={description || defaultDescription} />
      <meta
        name="viewport"
        content="initial-scale=1.0, maximum-scale=1.0, width=device-width, user-scalable=no"
      />
      <meta name="robots" content="noindex, nofollow" /> // Dont pick this to production
      <meta name="author" content="Home Credit Indonesia" />
      <link rel="canonical" href={defaultURL + (url || "")} />
      <meta property="fb:profile_id" content="523957857647302" />
      <meta property="fb:app_id" content="1792946577416523" />
      <meta property="og:locale" content="id_ID" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={`${title} - Home Credit` || `${defaultTitle} - Home Credit`}
      />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta property="og:url" content={defaultURL + (url || "")} />
      <meta property="og:site_name" content="Home Credit Indonesia" />
      <meta property="og:image" content={imgContent || ogImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta
        property="og:street-address"
        content="Jl. TB Simatupang No. 53A South Jakarta, DKI Jakarta"
      />
      <meta property="og:postal-code" content="12520" />
      <meta property="og:country-name" content="Indonesia" />
      <meta property="og:email" content="care@homecredit.co.id" />
      <meta property="og:phone_number" content="(021) 2953 9655" />
      <meta
        property="og:see_also"
        content="https://www.facebook.com/homecreditid/"
      />
      <meta
        property="og:see_also"
        content="https://www.instagram.com/homecreditid/"
      />
      <meta property="og:see_also" content="https://twitter.com/homecreditid" />
      <meta
        property="og:see_also"
        content="https://www.linkedin.com/company/homecreditindonesia/"
      />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content={`${title} - Home Credit` || `${defaultTitle} - Home Credit`}
      />
      <meta
        name="twitter:description"
        content={description || defaultDescription}
      />
      <meta name="twitter:site" content="@homecreditid" />
      <meta property="twitter:image" content={imgContent || ogImage} />
      <meta name="twitter:image:width" content="1200" />
      <meta name="twitter:image:height" content="630" />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(dataSchemaCompany)
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(dataSchemaPage)
        }}
      />
      <script
        async
        dangerouslySetInnerHTML={{
          __html: `
          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "//www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "${process.env.gtmCode}");
          `
        }}
      />
      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="144x144" href={appleTouch144} />
      <link rel="apple-touch-icon" sizes="72x72" href={appleTouch72} />
      <link rel="apple-touch-icon" href={appleTouch57} />
      <link href="/favicon.ico" type="image/x-icon" rel="shortcut icon" />
      <link href="/favicon.ico" type="image/x-icon" rel="icon" />
      {/* Hotjar Tracking Code for www.homecredit.co.id */}
      <script
        async
        dangerouslySetInnerHTML={{
          __html: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1701605,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `
        }}
      />
      {/* Clevertap javascript library */}
      {/* <script
        async
        dangerouslySetInnerHTML={{
          __html: `   
          var clevertap = {
            event: [],
            profile: [],
            account: [],
            region: "sg1",
            onUserLogin: [],
            notifications: [],
            privacy: []
          };
          clevertap.account.push({ id: "TEST-K57-645-995Z" });
          clevertap.privacy.push({ optOut: false }); //set the flag to true, if the user of the device opts out of sharing their data
          clevertap.privacy.push({ useIP: false }); //set the flag to true, if the user agrees to share their IP data
          (function () {
            var wzrk = document.createElement('script');
            wzrk.type = 'text/javascript';
            wzrk.async = true;
            wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/a.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(wzrk, s);
          })();
          clevertap.notificationCallback = function(msg){
          clevertap.raiseNotificationViewed();
          console.log(JSON.stringify(msg));
          };
        `
        }}
      /> */}
    </Head>
  );
};

export default NextHead;
